.contact-container {
	border-radius: 15px !important;
	background-color: var(--defaultBlue) !important;

	padding: 40px 20px;
	height: 100%;
	color: white;


}

#contactForm {

	.form-group {
		margin-top: 20px;

		+div {
			height: 60px;

			>input {
				height: inherit;
			}
		}

		#message {
			min-height: 200px;
		}
	}
}

.text-muted {
	color: gainsboro !important;
	font-size: large;
}

.btn-contact {
	color: gainsboro !important;
	margin-top: 60px;
	border: 2px solid gainsboro;

}

.btn-contact:hover {
	color: var(--defaultBlue) !important;
	background-color: gainsboro;
	border: 2px solid gainsboro;
}

/* 
.Mui-focused {
	color: rgb(105, 37, 90) !important;
	font-weight: bold;
	text-shadow: #FFF;
} */