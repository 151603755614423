& .theme:has(.dark) {

	& .menu-bg > ul {
		background-color: #fff !important;
  
		& svg {
			fill: unset !important;
		}
	  }

	background-color: #000204 !important;

	& .App-header {
		background-color: #000204 !important;

	}


	& h1,
	h2 {
		color: #FFF;
	}

	& footer {
		color: #FFF;
	}

	& .MuiPaper-root {


		& svg {
			fill: #FFF !important;
		}
	}

	& .card {
		background-color: #061c31 !important;


		& .list li {
			color: #fff;
		}
	}

	& .MuiPaper-root:has(.card-details) {
		background-color: #061c31 !important;
		color: #FFF;
		border-color: #061c31;
		border-radius: 30px;

		& ul {
			color: #FFF;
		}

		& h1,
		h2 {
			color: #FFF;
		}


		& .item-intro {
			color: #FFF;
		}


	}

	& button {
		background-color: #FFF !important;
		color: #061c31 !important;
		font-weight: 600;

	}



	& .item-intro {
		color: #FFF !important;
	}

}


& .theme:has(.light) {

	& .menu-bg {
		background-color: --default-blue !important;
  
	  }

	& h1,
	h2 {
		color: #061c31;
	}

	& footer {
		color: #061c31;
	}

	& .MuiPaper-root {
		background-color: #FFF !important;

		& svg {
			fill: #061c31 !important;
		}
	}

	& .card {
		background-color: #061c31 !important;


		& .list li {
			color: #fff;
		}
	}

	& button {
		background-color: #061c31  !important;
		color: #FFF !important;
		font-weight: 600;

	}

	& .MuiPaper-root:has(.card-details) {
		background-color: #061c31 !important;
		color: #FFF;
		border-color: #061c31;
		border-radius: 30px;

		& ul {
			color: #FFF;
		}

		& h1,
		h2 {
			color: #FFF;
		}


		& .item-intro {
			color: #FFF;
		}


	}

	& .MuiStack-root ul {
		background-color: rgb(6, 28, 49) !important;
		color: #FFF;

		& svg {
			fill: #FFF !important;
		}

		/* & .card-details .MuiTypography-root,
		.MuiPaper-root {
			background-color: #061c31 !important;
			color: #FFF;
			border-color: #061c31;
		} */
	}

	



}