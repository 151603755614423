.portfolio-container {
	display: grid;
	/* grid-template-columns: auto auto auto; */
	grid-template-columns: repeat(auto-fill, 23vh);
	grid-gap: 2.5rem;
	padding: 2rem 4rem 2rem;
  }
  .portfolio-container .wrapper {
	transform: skew(-10deg);
	transition: 0.25s;
  }
 
  .portfolio-container .wrapper:before {
	content: '';
	left: 0;
	top: 0;
	height: calc(100% - 10px);
	width: calc(100% - 10px);
	border-radius: 40px;
	opacity: 0;
	border: 2px solid #ece9e6;
	position: absolute;
	z-index: -1;
	transition: 0.5s 0.05s;
  }
  .portfolio-container .wrapper:hover:before {
	opacity: 1;
	transform: translateY(1.5rem) translateX(1.5rem);
  }
  .portfolio-container .wrapper .card:nth-child(1):after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/net.jpg");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }

  /* .portfolio-container .wrapper:nth-child(1) .card:hover {
	display: none;
	z-index: 1;
}

  .portfolio-container .wrapper:nth-child(1) .card-details:hover {
		display: block;
		z-index: 2;
		content: '';
  }
 */
  .portfolio-container .wrapper:nth-child(2) .card:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/webapi.jpg");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }
  .portfolio-container .wrapper:nth-child(3) .card:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/react.jpg");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }
  .portfolio-container .wrapper:nth-child(4) .card:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/frontend.png");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }
  .portfolio-container .wrapper:nth-child(5) .card:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/custom.png");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }
  .portfolio-container .wrapper:nth-child(6) .card:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../assets/portfolio/bi.jpg");
	background-size: cover;
	background-color: #373f51;
	background-blend-mode: screen;
	transform: skew(10deg) scale(1.2);
	transition: 0.25s;
  }
  /* .portfolio-container .wrapper:hover {
	transform: translateX(-1rem) translateY(-1rem) skew(-10deg);
  } 
  .portfolio-container .wrapper:hover .card {
	box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.1);
  }*/
  .portfolio-container .wrapper:hover .card:after {
	background-blend-mode: normal;
  }
  /* .portfolio-container .wrapper:hover .card .rarr {
	bottom: 4rem;
	background-color: rgba(255,255,255,0.8);
  } */
  .portfolio-container .card {
	background-color: #fff;
	border-radius: 30px;
	height: 17rem;
	padding: 1rem;
	position: relative;
	overflow: hidden;

	
  }
  .portfolio-container .card:after {
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: left;
	background-position-y: top;
	/* background-position: center; */
	/* background-position: 1em; */
  }

 /*  .portfolio-container .card .rarr {
	position: absolute;
	bottom: -1rem;
	right: 3rem;
	border-radius: 100%;
	padding: 1rem;
	font-size: 1.5rem;
	color: #373f51;
	z-index: 1;
	transform: skew(10deg) translateY(50%);
	text-align: center;
	transition: 0.25s;
  }

  .portfolio-container .wrapper .card-details {
	display:none;
	
  } */

  & .card-details .item-intro {
	color: rgb(24, 26, 27) !important;
	margin: 10px 0;
	text-align: center;

	& :after {
		content: '***';
	}

}

& .MuiTypography-root, .MuiPaper-root {
	border-radius: 30px;
	overflow: hidden;
	padding: 20px 10px;

	& .item-intro {
		color: #fff !important;
	}
}


iframe {
	body {
		font-size: smaller !important;
	  }

	.ob-smartfeed-wrapper, .ob-widget {
	display: none !important;
  }
}
  
