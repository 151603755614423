:colors {
  --defaultBlue: #061C31
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  text-transform: uppercase;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.techni-menu {
  border-radius: 20px;
  position: fixed !important;
  z-index: 99999;
  padding-bottom: 10px;

}

.menu-bg {
  display: flex;
  position: relative !important;
  z-index: 99999;
  height: fit-content;
  padding-top: 10px;
  place-items: center;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media only screen {
  .MuiPaper-root:has(ul) {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .team-member.box {
    max-width: 100px;
    max-height: 100px;
    min-height: 130px;
    min-width: 130px;

    .rounded-circle {
      display: unset;
      align-items: normal;
      inset: unset;
      margin: unset;
    }


  }

  .timeline-image {
    max-width: 100px;
    max-height: 100px;

  }

  .team-member-title {
    flex-direction: column !important;
    display: flex;
    flex-direction: row;
    background-color: grey;
    padding: 50px 40px;
    margin: 0px 0px 30px 0px;
    border-radius: 60px;


  }

  p {
    padding: 0 10px !important;
  }

  .timeline:before {
    left: 50%;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 80px;
  }

  .timeline>li .timeline-panel {
    float: left;
    /* width: 41%; */
    padding: 0 25px 20px 20px;
    text-align: right;
  }

  .timeline>li .timeline-image {
    left: 50%;
    /* width: 100px;
      height: 100px; */
    margin-left: -50px;
  }

  .timeline>li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 25px 20px 20px;
    text-align: left;
  }

  .timeline .text-muted {
    width: 90%;
  }

  #children {
    width: 100%;

    &~.col-md-4 {
      width: 100%;
      display: inline-flex !important;
    }

    &~.col-md-7 {
      width: 100%;
      display: inline-flex !important;
    }


  }
}




@media screen and (max-width:961px) {

  .MuiDivider-flexItem {
    display: none;

  }

  .menu-bg {
    margin-bottom: 20px !important;
    /* margin: 0 10px !important; */
    /* width: 80% !important; */
    /* margin-top: 50px !important; */

    .techni-menu {
      height: 100%;
      display: flex;
      justify-content: center !important;
      /* position: fixed; */
      height: max-content;
      /* width: 99%;
      margin-top: 2em;
      top: 2%; */
      padding: 0 ;



      li[role=menuitem] {
        max-width: 45px !important;
        float: left;
        justify-content: space-evenly;
      }
    }
  }

  .theme:has(.light) {

    & .MuiPaper-root {
      background-color: transparent !important;

    }


  }

  .theme:has(.dark) {

    & .menu-bg>ul {
      background-color: #fff !important;

      & svg {
        fill: unset !important;
      }
    }
  }

  .App-header~div {
    display: block !important;


    & #container-main {
      /* margin-top: 2em !important; */
      position: relative;
      top: 3em;
      padding-bottom: 20px;

    }

  }


  .timeline>li .timeline-panel {
    float: left;
    padding: 0 25px 20px 20px;
    text-align: right;


    &.left,
    &.right {
      width: 100%;
    }
  }

  .timeline>li {
    margin-bottom: 20px !important;
  }


  .timeline-image {
    max-width: 100px;
    max-height: 100px;
    position: relative !important;
    margin-bottom: 10px !important;
  }

  .timeline:before {
    left: 50%;
    content: normal !important;
  }

  .text-muted {
    width: 100% !important;
    text-align: center !important;
  }

  .team-member-title {
    flex-direction: column !important;
    display: flex;
    flex-direction: row;
    background-color: grey;
    padding: 30px 20px;
    margin: 0px 0px 30px 0px;
    border-radius: 60px;

  }

  .portfolio-container {
    justify-content: center;
    grid-template-columns: unset;
  }

  .portfolio-container .wrapper {
    min-width: 240px !important;
    max-width: 340px !important;
    width: 100% !important;
    margin-left: -10px;

  }

  .footer {
    margin-top: 50px !important;

     /* div:first-child::after{
      content:  "\2503";
      left: calc( 20vw - 8vw);
      position: relative;
      bottom: 40%;
    
    } */
  }

}

@media screen and (min-width: 540px) and (max-width: 912px) {
  .theme:has(.light) {

    & .MuiPaper-root {
      background-color: transparent !important;

    }

    & .menu-bg {
      background-color: --defaultBlue !important;

    }
  }

  .theme:has(.dark) {

    & .menu-bg>ul {
      background-color: #fff !important;

      & svg {
        fill: unset !important;
      }
    }
  }

  .App-header~div {
    display: block !important;
    /* margin-top: 7em !important; */

    & #container-main {
      /* margin-top: 4em !important; */
      position: relative;
      top: 3em;
      padding-bottom: 20px;
    }

  }

  .timeline-heading {
    width: unset;
  }

  .footer {
    margin-top: 50px !important;

     /* div:first-child::after{
      content:  "\2503";
      left: 0vw;

    
    } */
  }
}

/* Desktop styles */
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  /* Styles for desktops */

  & #container-main {
    margin-top: 4em !important;
    
  }

  .techni-menu {
    top: 9em;
    padding: 0;
  }

  .menu-bg {
    padding: 0 0 0 50px;
  }

  .portfolio-container {
    grid-gap: unset !important;
    grid-row-gap: 2.5em !important;
  }

  .timeline-heading {
    padding: 0 5px;
  }

  .text-muted {
    width: 100% !important;
    text-align: center;
  }

}

/* Larger screens styles */
@media screen and (min-width: 1440px) {
  /* Styles for larger screens */

  & #container-main {
    margin-top: 4em !important;

  }

  .menu-bg {
    padding: 0 0 0 50px;

  }

  .techni-menu {
    top: 9em;
    padding: 0;
  }

  .portfolio-container {
    grid-gap: unset !important;
    grid-row-gap: 2.5em !important;
  }

  .timeline-heading {
    padding: 0 5px;
  }
}