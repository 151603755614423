:root {
    --defaultBlue: #061C31;
}

* {

    box-sizing: border-box;
}

.social-buttons {
    text-align: center;
}

.timeline {
    position: relative;
    padding: 30px 0;
    list-style: none;
    background-color: var(--defaultBlue);
    color: white;
    border-radius: 60px;


}

.timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: '';
    background-color: #e9ecef;
}

.timeline>li {
    position: relative;
    min-height: 50px;
    margin-bottom: 50px;
}

.timeline>li:after,
.timeline>li:before {
    display: table;
    content: ' ';
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;

    &.left {
        float: left;
        width: 50%;
    }
    &.right {
        float: right;
        width: 50%;
    }
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .timeline-image {
    position: absolute;
    /* z-index: 100;*/
    left: 0;
    text-align: center;
    margin: 0 auto;
}

.timeline-image {
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    background-color: var(--defaultBlue);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    border: 3px solid #e9ecef;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
    }
}

.timeline>li .timeline-image h4 {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;

}

.timeline>li .text-bubble {
    width: 100%;
    height: 100%;

}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}

.timeline-heading {
    width: 80%;
    text-align: center;
    margin: 0 30px;
}
.timeline .timeline-heading h4.subheading {
    text-transform: none;
}

.timeline .timeline-body>ul,
.timeline .timeline-body>p {
    margin-bottom: 0;
}

.timeline .timeline-body>.text-muted {
    color: lightgrey !important;
    font-size: 1.2rem;
    text-align: left;
    margin: 0 auto;

    &.left {
        float: left;
    }
    &.right {
        float: right;
        text-align: left;
        text-wrap: pretty;
    }
}

.text-bold {
    color: var(--defaultBlue);
    font-size: 1em;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

img {
    width: 100% !important;
    transition: transform .5s ease;
    max-width: fit-content;
}

@keyframes backgroundScroll {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(3) translateX(-30px) translateY(35px);
    }


    50% {
        /* transform: scale(3) translateX(20px) translateY(-40px); */
        transform: scale(5) translateX(-25px) translateY(-25px);

    }

    75% {
        transform: scale(3) translateX(8px) translateY(30px);
    }

    100% {
        transform: scale(5) translateX(-23px) translateY(20px);

    }

}

.timeline-image>.dive-deep:hover, .timeline-image>.dive-deep:active {

    animation: backgroundScroll 15s ease;

}

.rounded-circle {
    padding: 3px 0px;
}

.team-member.box {
    position: relative;
    max-width: 250px;
    max-height: 250px;
    background-color: gainsboro;
    border-radius: 50%;
    overflow: hidden;

    margin: 0 auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

.team-member.box::before {
    content: "";
    position: absolute;
    inset: -10px 100px;
    background: linear-gradient(grey, var(--defaultBlue));
    transition: 0.5s;
    -webkit-animation: profile_animate 4s linear infinite;
    animation: profile_animate 4s linear infinite;
}

.team-member.box::after {
    content: "";
    position: absolute;
    background-color: var(--defaultBlue);
    inset: 10px;
    border-radius: 50%;
    z-index: 1;
}

.team-member.box .rounded-circle {
    position: absolute;
    inset: 30px;
    border: 6px solid grey;
    z-index: 3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    margin: -18px -11px 0 -18px;
}

.team-member.box .rounded-circle img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.5s;
    text-align: center;
    pointer-events: none;
    z-index: 3;
}

.team-member.box .rounded-circle h4 {
    /* position: relative; */
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 0;
}

.team-member.box .rounded-circle h4 span {
    display: block;
    font-weight: 300;
    font-size: 13px;
    padding: 5px 0;
}

.team-member.box .rounded-circle a {
    position: relative;
    background-color: #fff;
    color: var(--defaultBlue);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 3px;

}

.team-member.box a {
    letter-spacing: 3px;
    color: #fff;
}

.team-member.box:active, .team-member.box:hover {
    z-index: 3;
} 
/* 
.team-member.box:hover::before {
    inset: -20px 0px;
}
*/
.team-member.box:hover img,.team-member.box:active img {
    opacity: 0;
    z-index: 3;

    ~ a {
        z-index: 3;
    }
}

@-webkit-keyframes profile_animate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes profile_animate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}



.team-member {
    margin-bottom: 50px;
    text-align: center;

}

.team-member img {
    width: 100%;
    height: 100%;
    max-width: 225px;
    max-height: 225px;
    border: 7px solid rgba(0, 0, 0, 0.1);

}

.team-member h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
}

.team-member p {
    margin-top: 0;
}

