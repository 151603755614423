:colors {--defaultBlue: #061C31 }


@import "Home.css";
@import "Contact.css";
@import "Mode.css";
@import "index.css";


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  display: flex;
  justify-content: center;
  /* position: fixed; */
  width: 100%;
   background-color: #FFF;
 /* padding-top: '12vh'; */
  flex-direction: row;
  z-index: 10;

}

.theme {
  height: auto !important
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body, html {
  height: 100%;
}

.parallaxbg {
  /* The image used */
  background-image: url("../assets/TC2023.svg");

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

iframe html::before {
  border-radius: 15px !important;
  background-color: var(--defaultBlue) !important;
  padding-top: 0px;
  margin-top: 0px;
}

iframe {
  width: 100%;
  min-height: 1000px;
  border-radius: 30px;
  margin-top: 40px;


  html > body {
    width: inherit;
    height: inherit;
  }
}

#children div div div .row{
  width: 100%;
  margin-top: 0px;

}


